<template>
  <div>
    <mf-card-container title="Importar Calendário" description="Importar .csv com as ofertas do calendário">
      <template #headers>
        <mf-button label="Ajuda" icon="help" icon-position="right" color="primary" @click="goToManual" />
      </template>

      <div class="my-4">
        <vertical-step class="mb-4" :number="1" text="Selecione a indústria" />
        <div style="margin-left: 14px; width: 366px">
          <select-industry ref="industrySelection" v-model="industry" required />
        </div>

        <vertical-step class="mb-4" :number="2" text="Selecione o tipo da campanha" />
        <div style="margin-left: 14px; width: 30%;">
          <v-select
            v-model="selectedOfferType"
            :items="offersType"
            item-text="name"
            item-value="value"
            return-object
            label="Tipo da campanha"
            outlined
            :menu-props="{ offsetY: true }"
            @change="clearCalendar"
          />
        </div>

        <vertical-step class="mb-4" :number="3" text="Selecione a planilha .csv" />
        <div class="my-4" style="margin-left: 14px;">
          <import-csv @done="updateCalendar" @clear="clearCalendar" />
        </div>

        <div class="my-4">
          <error-alert :errors="templateErrors.invalidNumberColumns" />
          <error-alert :errors="templateErrors.notFoundHeaders" />
          <error-alert :errors="templateErrors.invalidHeaders" />
          <error-alert :errors="campaignErrors.invalidTypes" />
        </div>

        <div style="max-height: 300px; overflow: auto">
          <vue-json-pretty :deep="1" :data="jsonCalendarPreview" highlight-mouseover-node show-line />
        </div>
      </div>
    </mf-card-container>

    <div class="d-flex flex-row align-center justify-end mt-2" style="gap: 8px;">
      <mf-button label="Voltar" outlined color="error" @click="returnStep" />
      <mf-action-buttons
        :primary-button="{
          text: 'Continuar',
          action: goNextStep,
          isVisible: true,
          isDisabled: !jsonCalendar,
          isLoading: false
        }"
      />
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse'
import ImportCsv from '@/modules/industry/components/ImportCsv.vue'
import { validateTemplate, convertAndValidateCampaigns } from '@/modules/industry/helpers/templateValidation.js'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    VerticalStep: () => import('@/modules/industry/components/VerticalStep.vue'),
    ErrorAlert: () => import('@/modules/industry/mercapromo/views/components/ErrorAlert.vue'),
    SelectIndustry: () => import('@/modules/industry/components/SelectIndustry.vue'),
    ImportCsv,
    VueJsonPretty
  },
  data() {
    return {
      jsonCalendar: '',
      jsonCalendarPreview: '',
      offersType: [
        {
          name: 'Desconto Absoluto',
          value: 'general'
        },
        {
          name: 'Leve & Pague',
          value: 'takepay'
        },
        {
          name: 'Cashback',
          value: 'cashback'
        }
      ],
      selectedOfferType: {
        name: 'Desconto Absoluto',
        value: 'general'
      },
      industry: {},
      commonTemplateFields: [
        { name: 'title', type: 'string', required: false, maxLength: 200 },
        { name: 'description', type: 'string', required: false, maxLength: 200 },
        { name: 'campaigns_sms_start_date', type: 'date', required: false },
        { name: 'campaigns_sms_end_date', type: 'date', required: false },
        { name: 'campaigns_sms_send_date', type: 'date', required: false },
        { name: 'campaigns_sms_send_time', type: 'time', required: false },
        { name: 'campaigns_sms_messages_product_text', type: 'string', required: false },
        { name: 'retailers_id', type: 'int', required: true },
        { name: 'retailers_name', type: 'string', required: true },
        { name: 'retailers_segmentation_type', type: 'string', required: false },
        { name: 'retailers_segmentation_days', type: 'string', required: false },
        { name: 'retailers_segmentation', type: 'string', required: false },
        { name: 'retailers_segmentation_offer', type: 'boolean', required: false },
        { name: 'retailers_discounts_units', type: 'string', required: true },
        { name: 'retailers_discounts_proposal_deadline', type: 'date', required: true },
        { name: 'retailers_discounts_terms', type: 'string', required: false },
        { name: 'retailers_discounts_start_date', type: 'date', required: true },
        { name: 'retailers_discounts_end_date', type: 'date', required: true },
        { name: 'retailers_discounts_sale_products_ean', type: 'string', required: true },
        { name: 'retailers_discounts_sale_products_name', type: 'string', required: false },
        { name: 'retailers_discounts_sale_products_maximum_per_client', type: 'int', required: false },
        { name: 'proposal_id', type: 'string', required: false },
        { name: 'products_group', type: 'boolean', required: false }
      ],
      generalTemplateFields: [
        { name: 'campaigns_sms_messages_general_text', type: 'string', required: false },
        { name: 'campaigns_sms_messages_price_1', type: 'string', required: false },
        { name: 'campaigns_sms_messages_price_2', type: 'string', required: false },
        { name: 'retailers_discounts_sale_products_price_1', type: 'float', required: true },
        { name: 'retailers_discounts_sale_products_price_2', type: 'float', required: true },
        { name: 'retailers_discounts_sale_products_rebate', type: 'float', required: false },
        { name: 'retailers_discounts_sale_products_minimum_per_client', type: 'int', required: false }
      ],
      takepayTemplateFields: [
        { name: 'campaigns_sms_messages_takepay_text', type: 'string', required: false },
        { name: 'campaigns_sms_messages_take', type: 'string', required: false },
        { name: 'campaigns_sms_messages_pay', type: 'string', required: false },
        { name: 'retailers_discounts_sale_products_take', type: 'int', required: true },
        { name: 'retailers_discounts_sale_products_pay', type: 'int', required: true }
      ],
      cashbackTemplateFields: [
        { name: 'campaigns_sms_messages_cashback_text', type: 'string', required: false },
        { name: 'campaigns_sms_messages_cashback_absolute_discount', type: 'string', required: false },
        { name: 'campaigns_sms_messages_cashback_expiration_date', type: 'string', required: false },
        { name: 'retailers_discounts_sale_products_cashback_absolute_discount', type: 'int', required: true },
        { name: 'retailers_discounts_sale_products_cashback_expiration_date', type: 'date', required: true },
        { name: 'retailers_discounts_sale_products_minimum_per_client', type: 'int', required: false }
      ],
      templateErrors: {
        invalidNumberColumns: [],
        notFoundHeaders: [],
        invalidHeaders: []
      },
      campaignErrors: {
        invalidTypes: []
      }
    }
  },
  computed: {
    hasErrors() {
      return (
        Object.values(this.templateErrors).some(arrayError => arrayError.length > 0) ||
        Object.values(this.campaignErrors).some(arrayError => arrayError.length > 0)
      )
    }
  },
  methods: {
    returnStep() {
      this.$emit('returnStep')
    },

    goNextStep() {
      this.$refs.industrySelection.$v.$touch()
      if (!this.$refs.industrySelection.valid()) return
      this.$emit('nextStep', this.jsonCalendar, this.selectedOfferType.value, this.industry)
    },

    goToManual() {
      window.open('https://mercafacil.getoutline.com/doc/manual-backoffice-mercatrade-9LPk47mUBB', '_blank')
    },

    clearCalendar() {
      this.jsonCalendar = ''
      this.jsonCalendarPreview = ''
      this.templateErrors = {
        invalidNumberColumns: [],
        notFoundHeaders: [],
        invalidHeaders: []
      }
      this.campaignErrors = {
        invalidTypes: []
      }
    },

    updateCalendar(calendar) {
      this.clearCalendar()
      const parsed = Papa.parse(calendar.readed, { header: true, skipEmptyLines: true, dynamicTyping: true })
      let validatingFields = []
      switch (this.selectedOfferType.value) {
        case 'general':
          validatingFields = this.commonTemplateFields.concat(this.generalTemplateFields)
          break
        case 'takepay':
          validatingFields = this.commonTemplateFields.concat(this.takepayTemplateFields)
          break
        case 'cashback':
          validatingFields = this.commonTemplateFields.concat(this.cashbackTemplateFields)
          break
      }
      this.templateErrors = validateTemplate(parsed, validatingFields)
      const { errors, converted } = convertAndValidateCampaigns(parsed, validatingFields)
      this.campaignErrors = errors
      if (this.hasErrors) return

      if (this.selectedOfferType.value === 'general') {
        this.jsonCalendar = this.calculateRebate(converted)
      } else {
        this.jsonCalendar = converted
      }
      this.jsonCalendarPreview = this.jsonCalendar.slice(0, 100)

      this.$alert({
        alert_message: `${this.jsonCalendar.length} registro(s) importado(s) com sucesso.`,
        alert_title: 'Sucesso!',
        alert_color: 'success',
        alert_icon: 'mdi-check-circle'
      })
    },

    calculateRebate(convertedCampaigns) {
      convertedCampaigns.forEach(campaign => {
        campaign.retailers_discounts_sale_products_rebate =
          campaign.retailers_discounts_sale_products_rebate ??
          Math.round((campaign.retailers_discounts_sale_products_price_1 - campaign.retailers_discounts_sale_products_price_2) * 100) / 100
      })
      return convertedCampaigns
    }
  }
}
</script>

<style>
.gap-flex {
  display: flex;
  gap: 4px;
}
</style>
