<template>
  <mf-loading-dialog :loading="$apollo.loading">
    <base-page-layout title="Cadastro">
      <div class="stepper-container">
        <mf-stepper :steppers="steppers" colors-type="starlord" :step="step"></mf-stepper>
      </div>

      <create-first-step :class="step === 1 ? '' : 'd-none'" @nextStep="finishStep1" @cancel="cancel"></create-first-step>
      <create-second-step :class="step === 2 ? '' : 'd-none'" @nextStep="finishStep2" @returnStep="returnStep"></create-second-step>
      <create-third-step :class="step === 3 ? '' : 'd-none'" :loading="saving" @save="finishStep3" @returnStep="returnStep"></create-third-step>
    </base-page-layout>
  </mf-loading-dialog>
</template>

<script>
import CreateFirstStep from '../components/CreateFirstStep.vue'
import CreateSecondStep from '../components/CreateSecondStep.vue'
import CreateThirdStep from '../components/CreateThirdStep.vue'
import { MUTATION_INSERT_MERCATRADE } from '@/modules/industry/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    CreateFirstStep,
    CreateSecondStep,
    CreateThirdStep
  },
  data() {
    return {
      step: 1,
      mercatradeTerms: '',
      mercatradeCalendar: {},
      mercatradeName: '',
      mercatradeStartDate: null,
      mercatradeEndDate: null,
      mercatradeTest: false,
      mercatradeTestDbName: '',
      saving: false,
      offerType: '',
      industry: {},
      steppers: [
        {
          step: 1,
          complete: false,
          stepName: 'Importar Termos e Condições',
          pathName: ''
        },
        {
          step: 2,
          complete: false,
          stepName: 'Importar Calendário',
          pathName: ''
        },
        {
          step: 3,
          complete: false,
          stepName: 'Dados Calendário',
          pathName: ''
        }
      ]
    }
  },
  computed: {
    context() {
      return {
        uri: this.$microservicesUrls['accounts'],
        headers: {
          authorization: localStorage.getItem('session_id'),
          accountId: this.$route.params.id
        }
      }
    }
  },
  methods: {
    finishStep1(markdown) {
      if (markdown) {
        this.steppers[0].complete = true
        this.mercatradeTerms = markdown.replace(/\n/g, '\\n')
      }
      this.step += 1
    },

    finishStep2(calendar, offerType, industry) {
      if (calendar) {
        this.steppers[1].complete = true
        this.mercatradeCalendar = calendar
        this.offerType = offerType
        this.industry = industry
      }
      this.step += 1
    },

    async finishStep3(item) {
      if (item) {
        this.steppers[2].complete = true
        this.mercatradeName = item.name
        this.mercatradeStartDate = item.start_date
        this.mercatradeEndDate = item.end_date
        this.mercatradeTest = item.test
        this.mercatradeTestDbName = item.test_db_name
        await this.save()
      }
    },

    returnStep() {
      if (this.step > 1) this.step -= 1
    },

    cancel() {
      this.$router.push(`/mercatrade`)
    },

    async save() {
      this.saving = true
      let variables = {
        input: Object.assign(
          { terms: this.mercatradeTerms },
          { calendar: this.mercatradeCalendar },
          { name: this.mercatradeName },
          { start_date: this.mercatradeStartDate },
          { end_date: this.mercatradeEndDate },
          { test: this.mercatradeTest },
          { test_db_name: this.mercatradeTestDbName },
          { offer_type: this.offerType },
          { industry_id: this.industry._id }
        )
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_INSERT_MERCATRADE,
          variables,
          context: this.context
        })
        setTimeout(() => {
          this.$alert({
            alert_message: 'Mercatrade criado com sucesso.',
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
          this.$router.push(`/mercatrade`)
        }, 1000)
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao enviar campanha(s).',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle',
          action: () => {
            const { errors } = error.networkError.result
            this.$errorDialog({
              title: errors.length > 1 ? 'Detalhes dos erros' : 'Detalhes do erro',
              errors: errors
            })
          },
          action_label: 'Ver detalhes'
        })

        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const graphQLError = error.graphQLErrors[0]
          const details = graphQLError.message || 'Detalhes do erro indisponíveis'
          console.error(details)
        }
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stepper-container {
  margin-right: 10%;
  margin-left: 10%;
}
</style>
